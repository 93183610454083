<template>
  <div class="fixed inset-0 overflow-hidden bg-gray-400 bg-opacity-50" style="z-index: 998;" >
    <div class="absolute inset-0 overflow-hidden">
      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
        <div class="w-screen max-w-5xl">
          <div class="h-screen flex flex-col bg-white shadow-xl overflow-hidden">
            <div class="h-full">
              Content
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
name: "Detail"
}
</script>

<style scoped>

</style>